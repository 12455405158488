import COLORS from '../style/color';

const STATUS_BANNER_DISPLAY_SECONDS = 3000;
const MIN_FLIGHT_NUMBER_CHAR = 3;
const NOT_APPLICABLE = '--';

enum TaskType {
    assessment = 'Assessment',
    issueSummary = 'Issue Summary',
    issueLog = 'Issue Log',
    spare = 'Spare',
    tooling = 'Tooling',
    manpower = 'Manpower',
    disMessage = 'DisMessage',
    event = 'Event',
    aogReport = 'AOG Report',
    iocLogChat = 'IOCLogChat',
    iocLogManual = 'IOCLogManual',
    actionItem = 'Action Item',
    eventNote = 'EventNote',
    actionItemNote = 'EventTaskNote',
    iocAlert = 'IOCAlert',
    mcWrq = 'MC WorkRequest',
    mailService = 'Mail Service',
}

const RECOVERY_PLAN_TASK_TYPES = [TaskType.spare.toString(), TaskType.manpower.toString(), TaskType.tooling.toString()];

const RECOVERY_PLAN_STATUS = {
    ready: 'ready',
    inProgress: 'inprogress',
};

const EVENT_STATUS = {
    open: 'open',
    closed: 'closed',
    cancelled: 'cancelled',
    reopened: 'reopened',
    created: 'created',
};

const MC_LOG_STATUS = {
    closed: 'closed',
};

enum RiskLevel {
    high = 'HIGH',
    medium = 'MEDIUM',
    default = 'DEFAULT',
}

enum uploadMessage {
    success = 'Status Report has been successfully sent.',
    error = 'The version already sent.',
}

enum SpareAndToolingTitle {
    spare = 'Spare',
    Tooling = 'Tooling',
}

const SORT_FIELD = {
    latest: 'Latest',
    earliest: 'Earliest',
    completedStatus: 'completedStatus',
    latestDueDate: 'latestDueDate',
    earliestDueDate: 'earliestDueDate',
    taskId: 'taskId',
    byPkgId: 'byPkgId',
    desc: 'DESC',
    asc: 'ASC',
};
const COE_SORT_FIELD = {
    latestStartTime: 'Latest start time',
    earliestStartTime: 'Earliest start time',
    latestEndTime: 'Latest end time',
    earliestEndTime: 'Earliest end time',
    regAscending: 'Reg. ascending',
    regDescending: 'Reg. descending',
};

enum ReportCardStatus {
    completed = 'Completed',
    isInprogress = 'In progress',
    upcoming = 'Upcoming',
}

enum ReportCardLinkLabel {
    download = 'Download',
    view = 'View',
}

enum ReportType {
    aogUpdate = 'AOG update',
    serviceabilityUpdate = 'Serviceability update',
}

enum Position {
    left = 'left',
    center = 'center',
    right = 'right',
}

enum TimeStatus {
    ets = 'ETS',
    ats = 'ATS',
}

enum GenPdfInfo {
    title = 'AOG Status Report',
    reason = 'Reason for AOG',
    pdfName = 'Status Report',
    detailTitle = 'Aircraft Details',
    issueUpdateTitle = 'Issue Update',
    SparesAndToolingsTitle = 'Recovery Plan -Spares & Toolings',
}

enum GenPdfIssueUpdateLabel {
    no = 'No.',
    update = 'Update',
    ets = 'ETS',
    updateTime = 'Update Time',
}

enum StatusList {
    pendingWorkInstruction = 'Pending Work Instruction',
    pendingSparesToolings = 'Pending Spares/Toolings',
    pendingManpower = 'Pending Manpower',
    pendingAuthorisationDocument = 'Pending Authorisation Document',
    wipTroubleshooting = 'Work in progress - Troubleshooting',
    wipOpeningUp = 'Work in progress - Opening up',
    wipComponentReplacement = 'Work in progress - Component replacement',
    wipRepair = 'Work in progress - Repair',
    wipCuring = 'Work in progress - Curing',
    outstandingOperationalTest = 'Outstanding - Operational test',
    outstandingHighPowerRun = 'Outstanding - High Power Run',
    finalStage = 'Final stages - Close up and document sign-off',
    awaitingTowing = 'Awaiting Towing',
    defuelingRefueling = 'De-fuelling / Re-fuelling required',
    aircraftServiceable = 'Serviceable',
}

enum AircraftDetails {
    regNum = 'Reg no',
    latestETS = 'Latest ETS',
    latestATS = 'Latest ATS',
    location = 'Location',
    flightStatus = 'Flight Status',
    nextFlightETD = 'Next Flight ETD',
    airline = 'Airline',
    startDate = 'AOG Declared Date',
    ageOfEvent = 'Age of Event',
    owner = 'Owner',
    station = 'Station',
    description = 'Defect Description',
}

enum SparesAndToolings {
    actualArrival = 'Actual Arrival Date & Time (UTC)',
    comments = 'Comments',
    description = 'Description',
    estimatedArrival = 'Estimated Arrival Date & Time (UTC)',
    airline = 'Airline',
    flightDate = 'Flight Date (UTC)',
    flightNumber = 'Flight No.',
    partNumber = 'Part no.',
    quantity = 'Quantity',
    status = 'Status',
    title = 'Spare',
    trackingNumber = 'Tracking no.',
    transportMethod = 'Transport Method',
}

enum EventTimeType {
    utc = 'UTC',
    hkt = 'HKT',
}

enum DisMessageStatus {
    preSend = 'preSend',
    sent = 'sent',
}

enum FlightType {
    passengerFlight = 'J',
    cargoFlight = 'F',
}

enum LegStateType {
    cancelled = 'CNL',
    arrival = 'ARR',
}

enum FlightBarLabel {
    cancelled = 'cancelled',
}

enum AirlineType {
    CX = 'CX',
    LD = 'LD',
    KA = 'KA',
}

enum AirlineManufacturer {
    airBus = 'airbus',
    boeing = 'boeing',
}

enum ChatAudience {
    withCrew = 'EFF_MF_IOC',
    withoutCrew = 'MF_IOC',
}

enum IOCTUserRoleId {
    IOC = 1,
    MC = 2,
    MCP = 3,
    Crew = 4,
    CJR = 5,
    CRM = 6,
    HKIA = 7,
    Dispatch = 8,
    LOP = 9,
    SystemGenerated = 10,
    ICM = 11,
    COP = 12,
    Dashboard = 27,
}

enum LMTTUserRoleId {
    ENG = 13,
    MC2 = 14,
    'COE MC' = 15,
    'HK TRANSIT' = 16,
    MCP2 = 17,
    'COE PLN' = 18,
    MCSG = 19,
    TS = 20,
    INV = 21,
    'ENG - IOCT' = 22,
    'COE-INV' = 23,
    TRANSFORM = 24,
    PLN = 25,
    Hangar = 26,
    ITM = 33,
    AFHS = 34,
}

enum LMTTHaecoRoleId {
    Haeco = 28,
}

enum allRoleId {
    ALL = 99,
}

const UserRoleId = {
    ...IOCTUserRoleId,
    ...LMTTUserRoleId,
    ...allRoleId,
};

enum ChangeType {
    created = 'created',
    updated = 'updated',
    deleted = 'deleted',
}

const EventStatusLabelMap = {
    [EVENT_STATUS.open]: 'In Progress',
    [EVENT_STATUS.cancelled]: 'Cancelled',
    [EVENT_STATUS.closed]: 'Closed',
};

const RecoveryPlanItemsIdPrefixMap = {
    [SpareAndToolingTitle.spare]: 'S',
    [SpareAndToolingTitle.Tooling]: 'T',
};

const DismessageStatuslabelMap = {
    [DisMessageStatus.sent]: 'Completed',
    [DisMessageStatus.preSend]: 'In Progress',
};

const MCEmailGroupMap = {
    [AirlineManufacturer.airBus]: {
        [AirlineType.CX]: 'CX Airbus',
        [AirlineType.KA]: 'CX Airbus',
        [AirlineType.LD]: 'AHK Airbus',
        [NOT_APPLICABLE]: 'CX Airbus,AHK Airbus',
    },
    [AirlineManufacturer.boeing]: {
        [AirlineType.CX]: 'CX Boeing',
        [AirlineType.KA]: 'CX Boeing',
        [NOT_APPLICABLE]: 'CX Boeing,AHK Boeing',
    },
};

const FlightTypeDisplayMap = {
    [FlightType.passengerFlight]: 'Passenger Flight',
    [FlightType.cargoFlight]: 'Cargo Flight',
};

const TaskTypeDisplayMap = {
    [TaskType.spare]: 'Spares & tooling',
    [TaskType.tooling]: 'Spares & tooling',
    [TaskType.manpower]: 'Manpower',
};

const RecoveryPlanStatusDisplayMap = {
    [RECOVERY_PLAN_STATUS.ready]: 'Ready',
    [RECOVERY_PLAN_STATUS.inProgress]: 'In progress',
};

enum COEEventContentBlockName {
    eventInformation = 'eventInformation',
    aircraftInformation = 'aircraftInformation',
    relatedActivity = 'relatedActivities',
    eventDateAndTime = 'eventDateAndTime',
    attachments = 'attachment',
    actionItems = 'actionItem',
}

enum EventContentBlockName {
    eventInformation = 'eventInformation',
    coeEventInformation = 'coeEventInformation',
    aogEventInformation = 'aogEventInformation',
    mcEventInformation = 'mcEventInformation',
    eventDateAndTime = 'eventDateAndTime',
    aogEventDateAndTime = 'aogEventDateAndTime',
    coeEventDateAndTime = 'coeEventDateAndTime',
    aircraftInformation = 'aircraftInformation',
    iocAircraftInformation = 'iocAircraftInformation',
    coeAircraftInformation = 'coeAircraftInformation',
    additionalEventInformation = 'additionalEventInformation',
    affectedFlight = 'affectedFlight',
    mcAffectedAircraft = 'mcAffectedAircraft',
    editableAffectedFlight = 'editableAffectedFlight',
    impactedPorts = 'impactedPorts',
    route = 'route',
    coeRelatedActivity = 'coeRelatedActivities',
    coeAttachments = 'coeAttachment',
    coeActionItems = 'coeActionItem',
    mcDateAndTime = 'mcDateAndTime',
    handOverDateAndTime = 'handOverDateAndTime',
    handOverAircraftInformation = 'handOverAircraftInformation',
    handOverDetails = 'handOverDetails',
}

const mcRestrictedViewEventContentBlockNames: string[] = [
    EventContentBlockName.mcDateAndTime,
    EventContentBlockName.mcEventInformation,
    EventContentBlockName.mcAffectedAircraft,
    EventContentBlockName.handOverAircraftInformation,
    EventContentBlockName.handOverDetails,
    EventContentBlockName.handOverDateAndTime,
];

const ASSET_URL = {
    cdnjs: 'https://cdnjs.cloudflare.com/',
    jsDelivr: 'https://cdn.jsdelivr.net/',
    d3: 'https://d3js.org/',
};

const EXTERNAL_END_POINT = {
    microsoftLogin: 'https://login.microsoftonline.com/',
};

enum IocLogItemMenuText {
    editEntry = 'Edit entry',
    removeEntry = 'Remove entry',
    removeFromLog = 'Remove from Log',
}

enum MCLogItemMenuText {
    editEntry = 'Edit entry',
    removeEntry = 'Remove entry',
    logPermission = 'Log entry permissions',
}

enum MCEventListItemMenuText {
    editHandoverNote = 'Edit handover note',
    createWorkRequest = 'Create Work Request',
    viewDetails = 'View Details',
}

enum EventItemMenuText {
    editEvent = 'Edit event',
    closeEvent = 'Close event',
    reopenEvent = 'Reopen event',
    cancelEvent = 'Cancel event',
    editEventAssessment = 'Edit event assessment',
    previewReport = 'Preview report',
    exportHandoverNote = 'Export handover note',
    sendDisMessage = 'Send DisMessage',
    assignToUserGroup = 'Assign to a user group',
    reAssignToUserGroup = 'Re-assign to a user group',
    exportEventPDF = 'Export/email event PDF',
    viewLog = 'View details',
    declareServiceable = 'Declare serviceable',
    declareAOG = 'Declare AOG',
    sendStatusReport = 'Send status report',
    sendServiceabilityReport = 'Send serviceability report',
}

enum CreateEventFieldName {
    typeId = 'typeId',
    subTypeId = 'subTypeId',
    description = 'description',
    impactedPorts = 'impactedPorts',
    startTime = 'startTime',
    etsTime = 'etsTime',
    atsTime = 'atsTime',
    estEndTime = 'estEndTime',
    registration = 'registration',
    flightStatus = 'flightStatus',
    owner = 'owner',
    affectedFlights = 'affectedFlights',
    routes = 'routes',
    eventTitle = 'eventTitle',
    ataChapter = 'ataChapter',
    relatedActivities = 'relatedActivities',
    nextFlightDept = 'nextFlightDept',
    attachment = 'attachment',
    actionItems = 'actionItems',
    partNumber = 'partNumber',
    partQty = 'partQty',
    partNo = 'partNo',
    type = 'type',
    title = 'title',
    upload = 'upload',
    commodity = 'commodity',
    mtceCode = 'mtceCode',
    eventLabels = 'eventLabels',
    aircraftType = 'aircraftType',
    isHandoverReport = 'isHandoverReport',
    reporter = 'reporter',
    carrier = 'carrier',
    flightNumber = 'flightNumber',
    port = 'port',
    descriptionHtml = 'descriptionHtml',
}

enum CreateTriggerFieldName {
    description = 'description',
    commodity = 'commodity',
    mtceCode = 'mtceCode',
    title = 'title',
    ataChapter = 'ataChapter',
    owner = 'owner',
    acType = 'acType',
    taskId = 'taskId',
    daysBeforePkgStart = 'daysBeforePkgStart',
    partNumber = 'partNumber',
}

const ACTION_ITEM_FORM_DEFAULT_VALUE = {
    [CreateEventFieldName.type]: '',
    [CreateEventFieldName.title]: '',
    [CreateEventFieldName.description]: '',
    [CreateEventFieldName.owner]: '',
    [CreateEventFieldName.startTime]: '',
    [CreateEventFieldName.etsTime]: '',
    [CreateEventFieldName.upload]: [],
};

const EVENT_FORM_ALL_DEFAULT_VALUE = {
    [CreateEventFieldName.typeId]: '',
    [CreateEventFieldName.subTypeId]: '',
    [CreateEventFieldName.description]: '',
    [CreateEventFieldName.registration]: '',
    [CreateEventFieldName.flightNumber]: '',
    [CreateEventFieldName.port]: '',
    [CreateEventFieldName.commodity]: 'N/A',
    [CreateEventFieldName.flightStatus]: '',
    [CreateEventFieldName.impactedPorts]: [],
    [CreateEventFieldName.routes]: [],
    [CreateEventFieldName.affectedFlights]: [],
    [CreateEventFieldName.startTime]: '',
    [CreateEventFieldName.etsTime]: '',
    [CreateEventFieldName.atsTime]: '',
    [CreateEventFieldName.partNumber]: [],
    [CreateEventFieldName.eventTitle]: '',
    [CreateEventFieldName.ataChapter]: '',
    [CreateEventFieldName.owner]: '',
    [CreateEventFieldName.relatedActivities]: [
        { relatedActivityId: 0, relatedActivityType: '', ufi: '', details: null, freeTextId: '' },
    ],
    [CreateEventFieldName.mtceCode]: '',
    [CreateEventFieldName.nextFlightDept]: '',
    [CreateEventFieldName.attachment]: [],
    [CreateEventFieldName.actionItems]: [],
    [CreateEventFieldName.eventLabels]: [],
    [CreateEventFieldName.isHandoverReport]: true,
};

const AOG_EVENT_FORM_DEFAULT_FIELDS = [
    CreateEventFieldName.typeId,
    CreateEventFieldName.subTypeId,
    CreateEventFieldName.description,
    CreateEventFieldName.eventTitle,
    CreateEventFieldName.eventLabels,
    CreateEventFieldName.ataChapter,
    CreateEventFieldName.registration,
    CreateEventFieldName.flightNumber,
    CreateEventFieldName.port,
    CreateEventFieldName.flightStatus,
    CreateEventFieldName.owner,
    CreateEventFieldName.affectedFlights,
    CreateEventFieldName.startTime,
    CreateEventFieldName.etsTime,
    CreateEventFieldName.atsTime,
    CreateEventFieldName.isHandoverReport,
    CreateEventFieldName.aircraftType,
];

const NON_AOG_EVENT_FORM_DEFAULT_FIELDS = [
    CreateEventFieldName.typeId,
    CreateEventFieldName.subTypeId,
    CreateEventFieldName.description,
    CreateEventFieldName.eventTitle,
    CreateEventFieldName.eventLabels,
    CreateEventFieldName.ataChapter,
    CreateEventFieldName.aircraftType,
    CreateEventFieldName.registration,
    CreateEventFieldName.flightNumber,
    CreateEventFieldName.port,
    CreateEventFieldName.owner,
    CreateEventFieldName.affectedFlights,
    CreateEventFieldName.isHandoverReport,
];

const IOC_EVENT_FORM_DEFAULT_FIELDS = [
    CreateEventFieldName.typeId,
    CreateEventFieldName.subTypeId,
    CreateEventFieldName.description,
    CreateEventFieldName.registration,
    CreateEventFieldName.flightStatus,
    CreateEventFieldName.impactedPorts,
    CreateEventFieldName.routes,
    CreateEventFieldName.affectedFlights,
    CreateEventFieldName.startTime,
    CreateEventFieldName.etsTime,
];

const COE_EVENT_FORM_DEFAULT_FIELDS = [
    CreateEventFieldName.typeId,
    CreateEventFieldName.subTypeId,
    CreateEventFieldName.eventTitle,
    CreateEventFieldName.description,
    CreateEventFieldName.ataChapter,
    CreateEventFieldName.owner,
    CreateEventFieldName.registration,
    CreateEventFieldName.relatedActivities,
    CreateEventFieldName.startTime,
    CreateEventFieldName.etsTime,
    CreateEventFieldName.nextFlightDept,
    CreateEventFieldName.attachment,
    CreateEventFieldName.actionItems,
    CreateEventFieldName.partNumber,
    CreateEventFieldName.commodity,
    CreateEventFieldName.mtceCode,
];

enum HistoryLogEventChangeType {
    open = 'created',
    updated = 'updated',
    deleted = 'deleted',
    cancelled = 'cancelled',
    closed = 'closed',
    reopened = 'reopened', // TBC
}

enum DashboardFlightType {
    PAX = 'pax',
    CARGO = 'cargo',
}

enum IrregularitiesMap {
    DIV = 'Diversions',
    GRR = 'Ground Returns',
    FTR = 'Air Returns',
    CNL = 'Cancellations',
    RIN = 'Reinstated',
}

const IrregularitiesSeq = {
    DIV: 1,
    GRR: 2,
    FTR: 3,
    CNL: 4,
    RIN: 5,
};

enum LiveDashboardAPIFilters {
    schedule = 'departure,arrival',
    topDisruptions = 'irregularities,top5Delay,totalDelay',
    customerImpacts = 'tightConnection,totalPax',
    all = 'all',
}

enum DefinitionCategory {
    crewFdp = 'Crew FDP',
    curfew = 'Curfew',
    premiumService = 'Premium Service',
    cargo = 'Cargo',
    commercial = 'Commercial',
    paxConnection = 'Pax Connection',
    others = 'Others',
}

const DEFINTION_STATUS = {
    active: 'Active',
    future: 'Future',
    expired: 'Expired',
};

const DefinitionStatusLabelMap = {
    [DEFINTION_STATUS.active]: 'Active',
    [DEFINTION_STATUS.future]: 'Future',
    [DEFINTION_STATUS.expired]: 'Expired',
};

const DayOfWeekMapping = {
    1: 'Mon',
    2: 'Tue',
    3: 'Wed',
    4: 'Thu',
    5: 'Fri',
    6: 'Sat',
    7: 'Sun',
};

const flightFilteringItemList = [
    {
        name: AirlineType.CX,
        value: AirlineType.CX,
    },
    {
        name: AirlineType.LD,
        value: AirlineType.LD,
    },
];

enum FlightBarSortItem {
    Std = 'std',
    EtdAndAtd = 'etd',
    Sta = 'sta',
    Eta = 'eta',
}

const flightSortingItemList = [
    {
        name: 'STD',
        value: FlightBarSortItem.Std,
        default: true,
    },
    {
        name: 'ETD/ ATD',
        value: FlightBarSortItem.EtdAndAtd,
    },
    {
        name: 'STA',
        value: FlightBarSortItem.Sta,
    },
    {
        name: 'ETA',
        value: FlightBarSortItem.Eta,
    },
];

enum BroadcastTopic {
    PageRefresh = 'refresh-controller',
}

enum BroadcastAction {
    GroupChange = 'GROUP_CHANGE',
}

enum CRDTaskStatus {
    red = 'RED',
    yellow = 'YELLOW',
    green = 'GREEN',
}

// 4 Ms
enum MaintenanceType {
    resources = 'resources',
    spares = 'spares',
    tooling = 'tooling',
    equipment = 'equipment',
}

enum MaintenanceTypeDisplay {
    resources = 'Resources',
    spares = 'Spare',
    tooling = 'Tooling',
    equipment = 'Equipment/Facilities',
}

const checkReadinessFilter = [
    {
        name: 'Red Light',
        value: CRDTaskStatus.red,
    },
    {
        name: 'Amber Light',
        value: CRDTaskStatus.yellow,
    },
];

enum EventStatusType {
    open = 'open',
    outstanding = 'outstanding',
    inProgress = 'inProgress',
    onHold = 'onHold',
    resolved = 'resolved',
    approved = 'approved',
    rejected = 'rejected',
    closed = 'closed',
    cancelled = 'cancelled',
    hxArranged = 'HX Arranged',
    hxNoticed = 'HX Noticed',
    hxReceived = 'HX Received',
}

const EventStatusDisplayName = {
    [EventStatusType.open]: 'Outstanding',
    [EventStatusType.outstanding]: 'Outstanding',
    [EventStatusType.inProgress]: 'In Progress',
    [EventStatusType.onHold]: 'On Hold',
    [EventStatusType.resolved]: 'Resolved',
    [EventStatusType.approved]: 'Approved',
    [EventStatusType.rejected]: 'Rejected',
    [EventStatusType.closed]: 'Closed',
    [EventStatusType.cancelled]: 'Cancelled',
    [EventStatusType.hxArranged]: 'HX Arranged',
    [EventStatusType.hxNoticed]: 'HX Noticed',
    [EventStatusType.hxReceived]: 'HX Received',
};

export const eventStatusDisplayMapper = {
    [EventStatusType.open]: { display: EventStatusDisplayName.open, value: EventStatusType.open },
    [EventStatusType.outstanding]: { display: EventStatusDisplayName.outstanding, value: EventStatusType.outstanding },
    [EventStatusType.inProgress]: { display: EventStatusDisplayName.inProgress, value: EventStatusType.inProgress },
    [EventStatusType.onHold]: { display: EventStatusDisplayName.onHold, value: EventStatusType.onHold },
    [EventStatusType.resolved]: { display: EventStatusDisplayName.resolved, value: EventStatusType.resolved },
    [EventStatusType.approved]: { display: EventStatusDisplayName.approved, value: EventStatusType.approved },
    [EventStatusType.rejected]: { display: EventStatusDisplayName.rejected, value: EventStatusType.rejected },
    [EventStatusType.closed]: { display: EventStatusDisplayName.closed, value: EventStatusType.closed },
    [EventStatusType.cancelled]: { display: EventStatusDisplayName.cancelled, value: EventStatusType.cancelled },
    [EventStatusType.hxNoticed]: {
        display: EventStatusDisplayName[EventStatusType.hxNoticed],
        value: EventStatusType.hxNoticed,
    },
    [EventStatusType.hxArranged]: {
        display: EventStatusDisplayName[EventStatusType.hxArranged],
        value: EventStatusType.hxArranged,
    },
    [EventStatusType.hxReceived]: {
        display: EventStatusDisplayName[EventStatusType.hxReceived],
        value: EventStatusType.hxReceived,
    },
};

const coeEventStatusList = {
    general: [
        eventStatusDisplayMapper[EventStatusType.outstanding],
        eventStatusDisplayMapper[EventStatusType.inProgress],
        eventStatusDisplayMapper[EventStatusType.onHold],
        eventStatusDisplayMapper[EventStatusType.resolved],
        eventStatusDisplayMapper[EventStatusType.approved],
        eventStatusDisplayMapper[EventStatusType.rejected],
        eventStatusDisplayMapper[EventStatusType.closed],
        eventStatusDisplayMapper[EventStatusType.cancelled],
    ],
    deferralHx: [
        eventStatusDisplayMapper[EventStatusType.outstanding],
        eventStatusDisplayMapper[EventStatusType.inProgress],
        eventStatusDisplayMapper[EventStatusType.onHold],
        eventStatusDisplayMapper[EventStatusType.resolved],
        eventStatusDisplayMapper[EventStatusType.approved],
        eventStatusDisplayMapper[EventStatusType.rejected],
    ],
    close: [eventStatusDisplayMapper[EventStatusType.cancelled]],
    closeAndCancelled: [
        eventStatusDisplayMapper[EventStatusType.closed],
        eventStatusDisplayMapper[EventStatusType.cancelled],
    ],
    eventToHx: [
        eventStatusDisplayMapper[EventStatusType.closed],
        eventStatusDisplayMapper[EventStatusType.cancelled],
        eventStatusDisplayMapper[EventStatusType.hxNoticed],
        eventStatusDisplayMapper[EventStatusType.hxReceived],
        eventStatusDisplayMapper[EventStatusType.hxArranged],
    ],
    crd: [
        eventStatusDisplayMapper[EventStatusType.outstanding],
        eventStatusDisplayMapper[EventStatusType.inProgress],
        eventStatusDisplayMapper[EventStatusType.onHold],
        eventStatusDisplayMapper[EventStatusType.resolved],
        eventStatusDisplayMapper[EventStatusType.closed],
        eventStatusDisplayMapper[EventStatusType.cancelled],
    ],
    crdHxAndSystem: [
        eventStatusDisplayMapper[EventStatusType.outstanding],
        eventStatusDisplayMapper[EventStatusType.inProgress],
        eventStatusDisplayMapper[EventStatusType.onHold],
        eventStatusDisplayMapper[EventStatusType.resolved],
        eventStatusDisplayMapper[EventStatusType.closed],
    ],
};

enum RelatedActivityType {
    flight = 'affectedFlight',
    add = 'affectedDefect',
    maintenance = 'affectedMaint',
    packageId = 'affectedUmPackage',
    none = 'notRelated',
    procedureOrAdd = 'affectedProcedure',
    packageFreeTextId = 'affectedPackage',
}

const relatedActivityTypeDisplayNames = {
    [RelatedActivityType.add]: 'ADD number',
    [RelatedActivityType.flight]: 'Flight number',
    [RelatedActivityType.maintenance]: 'Maintenance Bar',
    [RelatedActivityType.packageFreeTextId]: 'PKG ID or WO number or Task ID', // ManualPKG ID/Task ID
    [RelatedActivityType.procedureOrAdd]: 'Task details', // Task
    [RelatedActivityType.packageId]: 'Package ID/Task ID', // Package ID/Task ID
    [RelatedActivityType.none]: 'Others/Miscellaneous',
};

enum ActionType {
    Event,
    ActionItem,
}

enum EventAndTaskActions {
    AddNote = 'Add a note',
    AddActionItem = 'Add action item',
    EditActionItem = 'Edit action item',
    EditEvent = 'Edit event',
    ExportPDF = 'Export event PDF',
    UpdateStatus = 'Update status',
    AddEvent = 'Create event',
}

enum TriggerAction {
    DiscardChanges = 'discard changes',
    Exists = 'exists',
    Delete = 'delete',
    Create = 'create',
    Edit = 'edit',
}

enum TimeTypes {
    STD = 'STD',
    ETD = 'ETD',
    ATD = 'ATD',
    STA = 'STA',
    ETA = 'ETA',
    ATA = 'ATA',
}

const statusColorScheme = {
    [EventStatusType.outstanding]: {
        bgcolor: COLORS.white,
        border: `1px solid ${COLORS.grayLight}`,
        color: COLORS.darkshadeYellow,
    },
    [EventStatusType.inProgress]: {
        bgcolor: COLORS.lavenderLighten,
        border: `1px solid ${COLORS.lavenderLighten}`,
        color: COLORS.borderYellow,
    },
    [EventStatusType.onHold]: {
        bgcolor: COLORS.lavenderLighten,
        border: `1px solid ${COLORS.lavenderLighten}`,
        color: COLORS.borderYellow,
    },
    [EventStatusType.resolved]: {
        bgcolor: COLORS.brightGray,
        border: `1px solid ${COLORS.brightGray}`,
        color: COLORS.cathayJade,
    },
    [EventStatusType.approved]: {
        bgcolor: COLORS.brightGray,
        border: `1px solid ${COLORS.brightGray}`,
        color: COLORS.cathayJade,
    },
    [EventStatusType.rejected]: {
        bgcolor: COLORS.summaryBgRed,
        border: `1px solid ${COLORS.summaryBgRed}`,
        color: COLORS.borderRed,
    },
    [EventStatusType.closed]: {
        bgcolor: COLORS.veryLightShadeGray,
        border: `1px solid ${COLORS.veryLightShadeGray}`,
        color: COLORS.grayDark,
    },
    [EventStatusType.cancelled]: {
        bgcolor: COLORS.shadowGray,
        border: `1px solid ${COLORS.shadowGray}`,
        color: COLORS.white,
    },
    [EventStatusType.hxArranged]: {
        bgcolor: COLORS.lavenderLighten,
        border: `1px solid ${COLORS.lavenderLighten}`,
        color: COLORS.borderYellow,
    },
    [EventStatusType.hxNoticed]: {
        bgcolor: COLORS.lavenderLighten,
        border: `1px solid ${COLORS.lavenderLighten}`,
        color: COLORS.borderYellow,
    },
    [EventStatusType.hxReceived]: {
        bgcolor: COLORS.brightGray,
        border: `1px solid ${COLORS.brightGray}`,
        color: COLORS.cathayJade,
    },
    [EventStatusType.open]: {
        bgcolor: COLORS.white,
        border: `1px solid ${COLORS.grayLight}`,
        color: COLORS.darkshadeYellow,
    },
    error: {
        bgcolor: COLORS.white,
        border: `1px solid ${COLORS.white}`,
        color: COLORS.white,
    },
};

enum EventType {
    technical = 'Tech',
    operational = 'Ops',
    request = 'Req',
    deferral = 'Def',
    handover = 'Handover',
    melExtension = 'MEL',
    crd = 'CRD',
    eventToHx = 'Event to HX',
    deferralHX = 'Def HX',
    operationalHX = 'Ops HX',
    requestHX = 'Req HX',
    melExtensionHX = 'Mel HX',
    crdHX = 'CRD HX',
    crdSys = 'CRD SYS',
}

const typeColorScheme = {
    [EventType.technical]: {
        bgcolor: COLORS.steelBlue,
        color: COLORS.white,
    },
    [EventType.operational]: {
        bgcolor: COLORS.purpleHepatica,
        color: COLORS.textDark,
    },
    [EventType.request]: {
        bgcolor: COLORS.pastelLightGray,
        color: COLORS.textDark,
    },
    [EventType.deferral]: {
        bgcolor: COLORS.pastelIcyBlue,
        color: COLORS.textDark,
    },
    [EventType.handover]: {
        bgcolor: COLORS.doveFeather,
        color: COLORS.white,
    },
    [EventType.melExtension]: {
        bgcolor: COLORS.melExtensionBg,
        color: COLORS.white,
    },
    [EventType.crd]: {
        bgcolor: COLORS.flightBarPaxIcon,
        color: COLORS.textDark,
    },
    [EventType.eventToHx]: {
        bgcolor: COLORS.eventToHxBgBlue,
        color: COLORS.textDark,
    },
    error: {
        bgcolor: COLORS.white,
        color: COLORS.white,
    },
};

const relatedActivityList = {
    default: [
        { display: 'Maintenance bar', value: RelatedActivityType.maintenance },
        { display: 'Package ID /  Work Task (UM lookup)', value: RelatedActivityType.packageId },
        { display: 'ADD', value: RelatedActivityType.add },
        { display: 'Arrival flight', value: RelatedActivityType.flight },
        { display: 'Others/Miscellaneous', value: RelatedActivityType.none },
    ],
    manualAndPackage: [
        { display: 'Maintenance bar', value: RelatedActivityType.maintenance },
        { display: 'Package ID /  Work Task (UM lookup)', value: RelatedActivityType.packageId },
        { display: 'Package ID /  Work Task (Manual)', value: RelatedActivityType.packageFreeTextId },
        { display: 'ADD', value: RelatedActivityType.add },
        { display: 'Arrival flight', value: RelatedActivityType.flight },
        { display: 'Others/Miscellaneous', value: RelatedActivityType.none },
    ],
    manualAndTask: [
        { display: 'Maintenance bar', value: RelatedActivityType.maintenance },
        { display: 'Package ID /  Work Task (UM lookup)', value: RelatedActivityType.procedureOrAdd },
        { display: 'Package ID /  Work Task (Manual)', value: RelatedActivityType.packageFreeTextId },
        { display: 'ADD', value: RelatedActivityType.add },
        { display: 'Arrival flight', value: RelatedActivityType.flight },
        { display: 'Others/Miscellaneous', value: RelatedActivityType.none },
    ],
    task: [
        { display: 'Maintenance bar', value: RelatedActivityType.maintenance },
        { display: 'Package ID /  Work Task (UM lookup)', value: RelatedActivityType.procedureOrAdd },
        { display: 'ADD', value: RelatedActivityType.add },
        { display: 'Arrival flight', value: RelatedActivityType.flight },
        { display: 'Others/Miscellaneous', value: RelatedActivityType.none },
    ],
    manual: [
        { display: 'Maintenance bar', value: RelatedActivityType.maintenance },
        { display: 'Package ID /  Work Task', value: RelatedActivityType.packageFreeTextId },
        { display: 'ADD', value: RelatedActivityType.add },
        { display: 'Arrival flight', value: RelatedActivityType.flight },
        { display: 'Others/Miscellaneous', value: RelatedActivityType.none },
    ],
};

const relatedActivityLookupLabels = {
    [RelatedActivityType.maintenance]: {
        search: 'Search for Maintenance bar',
        header: 'Add maintenance to the event',
        confirm: 'Add maintenance',
    },
    [RelatedActivityType.flight]: {
        search: 'Search for flight',
        header: 'Add arrival flight to the event',
        confirm: 'Add flight',
    },
    [RelatedActivityType.packageId]: {
        search: 'Search for PKG ID / WO',
        header: 'Add PKG ID or task to the event',
        confirm: 'Add Package ID/Task',
    },
    [RelatedActivityType.add]: {
        search: 'Search for ADD',
        header: 'Search for ADD',
        confirm: 'Add selected ADD',
    },
    [RelatedActivityType.procedureOrAdd]: {
        search: 'Search for PKG ID / WO',
        header: 'Add PKG ID or task to the event',
        confirm: 'Add Package ID/Task',
    },
    [RelatedActivityType.packageFreeTextId]: {
        search: 'Search for PKG ID / WO',
        header: 'Add PKG ID or task to the event',
        confirm: 'Add Package ID/Task',
    },
    default: {
        search: 'search',
        header: 'search',
        confirm: 'Add',
    },
};

const sortValuesMap = {
    [COE_SORT_FIELD.latestStartTime]: { orderedBy: 'start_time_utc', order: 'DESC' },
    [COE_SORT_FIELD.earliestStartTime]: { orderedBy: 'start_time_utc', order: 'ASC' },
    [COE_SORT_FIELD.latestEndTime]: { orderedBy: 'ets_time_utc', order: 'DESC' },
    [COE_SORT_FIELD.earliestEndTime]: { orderedBy: 'ets_time_utc', order: 'ASC' },
    [COE_SORT_FIELD.regAscending]: { orderedBy: 'registration', order: 'ASC' },
    [COE_SORT_FIELD.regDescending]: { orderedBy: 'registration', order: 'DESC' },
};

const eventTypeLegendList = [
    { display: 'Technical', value: EventType.technical },
    { display: 'Deferral', value: EventType.deferral },
    { display: 'Handover', value: EventType.handover },
    { display: 'Operational', value: EventType.operational },
    { display: 'Request', value: EventType.request },
    { display: 'MEL Extension', value: EventType.melExtension },
    { display: 'CRD', value: EventType.crd },
    { display: 'Event to HX', value: EventType.eventToHx },
];

const EventTypeIds = {
    mcTechnical: 11,
    deferral: 26,
    deferralHX: 20,
};

const EventSubTypeIds = {
    aog: 23,
    nonAog: 24,
    handoverNote: 25,
};

const EventOwnerCategory = {
    mc: 'MC',
    ioct: 'IOCT',
};

const PropertiesTypes = {
    AOGEvent: 'aogEvent',
    COECommodity: 'coeCommodity',
    LMTTAlertTypes: 'lmttAlertTypes',
    McilogUserGroups: 'mcilogUsersGroups',
};

const ownerList = [
    { display: 'IOC MC', value: 'IOC MC' },
    { display: 'COE MC', value: 'COE MC' },
    { display: 'MC SG', value: 'MC SG' },
];

const pageSizesList = [25, 50, 100, 150];

const pageLimitArray = pageSizesList.map((pageSize) => {
    return { display: pageSize.toString(), value: pageSize.toString() };
});

const mqNoteTitle = 'Latest UM MQ Note/Remarks';

const AccessType = {
    read: 'R',
    write: 'W',
};

enum OBS_MESSAGE_STATUS {
    failed = 'Failed',
    sent = 'Sent',
    pending = 'Success', // TODO:  mapping to this value according to Backend interface
}

const ObsMessageStatusLabelMap = {
    [OBS_MESSAGE_STATUS.failed]: 'Send Failed',
    [OBS_MESSAGE_STATUS.sent]: 'Sent',
    [OBS_MESSAGE_STATUS.pending]: 'Pending',
};

enum FileExtensions {
    jpg = '.jpg',
    jpeg = '.jpeg',
    png = '.png',
    gif = '.gif',
    txt = '.txt',
    log = '.log',
    unl = '.unl',
    csv = '.csv',
    eml = '.eml',
    pdf = '.pdf',
    xlsx = '.xlsx',
    doc = '.doc',
    docx = '.docx',
    xls = '.xls',
    ppt = '.ppt',
    pptx = '.pptx',
    tiff = '.tiff',
    msg = '.msg',
    mp4 = '.mp4',
    mov = '.mov',
    jfif = '.jfif',
    avi = '.avi',
    zip = '.zip',
    rar = '.rar',
    docm = '.docm',
    htm = '.htm',
    html = '.html',
    m4a = '.m4a',
    pptm = '.pptm',
    url = '.url',
    wav = '.wav',
    xlsm = '.xlsm',
    xml = '.xml',
}

const SUPPORT_FILE_TYPES = Object.values(FileExtensions).join(',');

const UPLOAD_FILE_NUM_LIMIT = 5;
const UPLOAD_FILE_ERROR_EXCEED_FILE_SIZE =
    'Unable to upload the file. Please check the file format and size then try again.';
const UPLOAD_FILE_ERROR_OVER_LIMIT = `Uploads are limited to ${UPLOAD_FILE_NUM_LIMIT} files. Please try again with fewer selections`;

const DEFERRAL_EVENT_DUE_DATE_REGEXP = /(Task Due Date:[\s]*)([\d]{4}-[\d]{2}-[\d]{2}T[\d]{2}:[\d]{2}:[\d]{2})/is;

export {
    CRDTaskStatus,
    FlightBarSortItem,
    flightFilteringItemList,
    flightSortingItemList,
    STATUS_BANNER_DISPLAY_SECONDS,
    TaskType,
    ReportCardStatus,
    ReportCardLinkLabel,
    ReportType,
    TimeStatus,
    AirlineType,
    EventTimeType,
    StatusList,
    UserRoleId,
    LMTTUserRoleId,
    LMTTHaecoRoleId,
    ChatAudience,
    DisMessageStatus,
    EVENT_STATUS,
    MC_LOG_STATUS,
    RiskLevel,
    SORT_FIELD,
    COE_SORT_FIELD,
    RECOVERY_PLAN_STATUS,
    RECOVERY_PLAN_TASK_TYPES,
    EventStatusLabelMap,
    RecoveryPlanItemsIdPrefixMap,
    TaskTypeDisplayMap,
    RecoveryPlanStatusDisplayMap,
    FlightTypeDisplayMap,
    MCEmailGroupMap,
    DismessageStatuslabelMap,
    MIN_FLIGHT_NUMBER_CHAR,
    EventContentBlockName,
    COEEventContentBlockName,
    AircraftDetails,
    SparesAndToolings,
    GenPdfIssueUpdateLabel,
    GenPdfInfo,
    Position,
    uploadMessage,
    ChangeType,
    ASSET_URL,
    IocLogItemMenuText,
    MCLogItemMenuText,
    EventItemMenuText,
    ACTION_ITEM_FORM_DEFAULT_VALUE,
    IOC_EVENT_FORM_DEFAULT_FIELDS,
    AOG_EVENT_FORM_DEFAULT_FIELDS,
    NON_AOG_EVENT_FORM_DEFAULT_FIELDS,
    COE_EVENT_FORM_DEFAULT_FIELDS,
    EVENT_FORM_ALL_DEFAULT_VALUE,
    HistoryLogEventChangeType,
    CreateEventFieldName,
    DashboardFlightType,
    IrregularitiesMap,
    IrregularitiesSeq,
    LiveDashboardAPIFilters,
    DefinitionCategory,
    DEFINTION_STATUS,
    DayOfWeekMapping,
    DefinitionStatusLabelMap,
    NOT_APPLICABLE,
    EXTERNAL_END_POINT,
    BroadcastTopic,
    BroadcastAction,
    MaintenanceType,
    MaintenanceTypeDisplay,
    checkReadinessFilter,
    EventStatusType,
    coeEventStatusList,
    RelatedActivityType,
    relatedActivityTypeDisplayNames,
    ActionType,
    EventAndTaskActions,
    TimeTypes,
    EventType,
    LegStateType,
    FlightBarLabel,
    statusColorScheme,
    typeColorScheme,
    relatedActivityList,
    relatedActivityLookupLabels,
    sortValuesMap,
    eventTypeLegendList,
    EventTypeIds,
    EventSubTypeIds,
    PropertiesTypes,
    ownerList,
    CreateTriggerFieldName,
    TriggerAction,
    MCEventListItemMenuText,
    pageSizesList,
    pageLimitArray,
    EventOwnerCategory,
    EventStatusDisplayName,
    mqNoteTitle,
    AccessType,
    ObsMessageStatusLabelMap,
    OBS_MESSAGE_STATUS,
    SUPPORT_FILE_TYPES,
    UPLOAD_FILE_NUM_LIMIT,
    UPLOAD_FILE_ERROR_EXCEED_FILE_SIZE,
    UPLOAD_FILE_ERROR_OVER_LIMIT,
    FileExtensions,
    DEFERRAL_EVENT_DUE_DATE_REGEXP,
    mcRestrictedViewEventContentBlockNames,
};
