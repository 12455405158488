/* eslint-disable no-unused-vars */
declare global {
    interface Window {
        config: {
            baseURL: string;
            ioctAdminApiUrl: string;
            chatServerApiUrl: string;
            chatServerWebSocketUrl: string;
            chatAppId: string;
            azureTenantId: string;
            azureClientId: string;
            ioctVersion: string;
            bombThreatDefaultAttachment: string;
            bombThreatDefaultAttachmentFileName: string;
            hyperlinkToVeoci: string;
        };
    }
}

const globalConfig = {
    baseURL: window.config.baseURL,
    ioctAdminApiUrl: window.config.ioctAdminApiUrl,
    chatServerApiUrl: window.config.chatServerApiUrl,
    chatServerWebSocketUrl: window.config.chatServerWebSocketUrl,
    chatAppId: window.config.chatAppId,
    azureTenantId: window.config.azureTenantId,
    azureClientId: window.config.azureClientId,
    ioctVersion: window.config.ioctVersion,
    bombThreatDefaultAttachment: window.config.bombThreatDefaultAttachment,
    bombThreatDefaultAttachmentFileName: window.config.bombThreatDefaultAttachmentFileName,
    hyperlinkToVeoci: window.config.hyperlinkToVeoci,
};

export default globalConfig;
