import { MIN_FLIGHT_NUMBER_CHAR } from '../constants/constants';
import { DateTime } from 'luxon';
import { DateFormat } from './dateHelper';
import { CommonText } from '../constants/text';

const HTML_TAG_REGEX = /<[^>]*>/g;

const ordinalSuffixs = {
    one: 'st',
    two: 'nd',
    few: 'rd',
    other: 'th',
};

const isValidText = (text: string): boolean => {
    if (!text || text === CommonText.notApplicable) {
        return false;
    }
    return true;
};

const covertOrdinalNumber = (input: string | number) => {
    const ordinalRules = new Intl.PluralRules('en', { type: 'ordinal' });
    const suffix = ordinalSuffixs[ordinalRules.select(+input)];
    return `${input}${suffix}`;
};

const getFlightNumberDisplay = (airline: string, flightNumber: string): string => {
    if (!isValidText(airline) || !isValidText(flightNumber)) {
        return CommonText.notApplicable;
    }
    return `${airline}${flightNumber.padStart(MIN_FLIGHT_NUMBER_CHAR, '0')}`;
};

const getRegDescriptionWithPrefix = (reg: string): string => {
    if (!isValidText(reg)) {
        return CommonText.notApplicable;
    }
    return reg.length > 3 ? `${reg.slice(0, reg.length - 3)}-${reg.slice(reg.length - 3, reg.length)}` : reg;
};

const getDisplayText = (text?: string | null) => text || CommonText.notApplicable;

const getCommodityText = (commodity?: string) => {
    // when the backend gives the value of the commodity, for example, HX-SPPLY, it needs to be processed as HX value for the event form dropdown to be displayed properly.
    if (commodity?.includes('HX')) {
        return 'HX';
    } else {
        return commodity || '--';
    }
};

const getDisplayArray = (array: string[]): string => {
    if (!Array.isArray(array)) return array || CommonText.notApplicable;
    if (array.length > 0) return array.join(', ');

    return CommonText.notApplicable;
};

// input: CX25520211215HKGLHR1 or CX25520211215HKGLHR
const getFlightNumberDateOriginDestinationFromUfi = (dateString: string) => {
    if (!dateString || typeof dateString !== 'string') return {};
    const matchingArray = dateString.match(/([a-zA-Z]{3,6})\d?/);
    if (!matchingArray) return {};
    const [, ports] = matchingArray;
    const originPort = ports?.substring(0, 3);
    const destinationPort = ports?.substring(3, 6);
    const cutString = dateString?.split(ports)[0];
    const date = cutString?.slice(-8);
    const year = Number(date.slice(0, -4));
    const month = Number(date.slice(4, -2));
    const day = Number(date.slice(-2));
    const dateStr = DateTime.utc(year, month, day).toFormat(DateFormat.dateOnly);
    const carrier = cutString?.substring(0, 2);
    const flightNumber = cutString?.substring(2, cutString.length - 8)?.padStart(MIN_FLIGHT_NUMBER_CHAR, '0');
    return { flightNumber: carrier + flightNumber, dateStr, originPort, destinationPort };
};

// output: CX251/31Dec21/TPE-KIX
const convertUfiToReadableFormat = (ufi: string) => {
    const result = getFlightNumberDateOriginDestinationFromUfi(ufi);
    if (JSON.stringify(result) === '{}') return '--'; // error handle

    const { flightNumber, dateStr, originPort, destinationPort } = result;
    return `${flightNumber}/${dateStr}/${originPort}-${destinationPort}`;
};

const getTimeWithMinDigitInString = (time: number) => {
    if (time < 0) return '';
    if (time.toString().length === 1) {
        return `0${time}`;
    }
    return time.toString();
};

const htmlLabelToText = (text: string) => {
    if (!document) return text;
    const dom = document.createElement('div');
    dom.innerHTML = text;
    return dom.innerText;
};

const stripHtmlTag = (htmlString: string) => {
    return htmlString.replace(new RegExp(HTML_TAG_REGEX), '');
};

const addCommasToNumber = (value: string | number) => {
    const strNum = (typeof value === 'string' ? value : value?.toString()) || '0';
    return strNum.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const titleCase = (word: string): string => {
    if (!word) return '';
    const allLowerCase = word.toLowerCase();
    const words = allLowerCase.split(' ');
    const titleCaseWords = words.map((word) => `${word.charAt(0).toUpperCase()}${allLowerCase.slice(1)}`);
    return titleCaseWords.join(' ');
};

const getFileExtension = (fileName: string): string => {
    return fileName.substring(fileName.lastIndexOf('.')).toLowerCase();
};


const escapeRegex = (string: string) => {
    return string.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');
};


export {
    covertOrdinalNumber,
    getDisplayText,
    getDisplayArray,
    getFlightNumberDateOriginDestinationFromUfi,
    getFlightNumberDisplay,
    getRegDescriptionWithPrefix,
    convertUfiToReadableFormat,
    isValidText,
    getTimeWithMinDigitInString,
    htmlLabelToText,
    stripHtmlTag,
    addCommasToNumber,
    titleCase,
    getCommodityText,
    getFileExtension,
    escapeRegex,
};
